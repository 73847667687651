<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-6 c g">
      <div class="card">
        <div class="card-header">
          <h4 class="text-center">
            <i class="fa fa-usd"></i>
            {{ e("salary-report") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for="">{{ e("select-month") }}</label>
            <input type="month" class="form-control" v-model="month" />
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-success" @click="viewReport()">
              <i class="fa fa-search"></i>
              {{ e("view") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" v-if="reports">
      <div class="card card-body">
        <div class="col-12 table-responsive">
          <button class="btn btn-primary" @click="printData">
            <i class="fa fa-print"></i>
            {{ e("print") }}
          </button>
          <br />
          <br />
          <table
            class="table table-hover table-bordered custom-table"
            ref="printTable"
          >
            <thead>
              <th>
                {{ e("member") }}
              </th>
              <th>
                {{ e("total-attends") }}
              </th>
              <th>
                {{ e("total-ghiab") }}
              </th>
              <th>
                {{ e("freedays") }}
              </th>
              <th>
                {{ e("salary") }}
              </th>
              <th>
                {{ e("salary-discount") }}
              </th>
              <th>
                {{ e("total-salary") }}
              </th>
            </thead>
            <tbody>
              <tr v-for="report in reports" :key="report.member._id">
                <td>
                  {{ report.member.name }}
                  <br />
                  <small>{{ report.member.number }}</small>
                </td>
                <td>
                  {{ report.counts.status_2 ? report.counts.status_2 : 0 }}
                </td>
                <td>
                  {{
                    count(report.list) -
                    ((report.counts.status_2 ? report.counts.status_2 : 0) +
                      (report.counts.status_3 ? report.counts.status_2 : 0) +
                      report.freedays.length)
                  }}
                </td>
                <td>
                  {{ report.freedays.length }}
                </td>
                <td>
                  {{ report.member.salary }}
                </td>
                <td>
                  {{
                    ((count(report.list) -
                      ((report.counts.status_2 ? report.counts.status_2 : 0) +
                        (report.counts.status_3 ? report.counts.status_2 : 0) +
                        report.freedays.length)) *
                      report.member.salary) /
                    30
                  }}
                </td>
                <td>
                  {{
                    report.member.salary -
                    ((count(report.list) -
                      ((report.counts.status_2 ? report.counts.status_2 : 0) +
                        (report.counts.status_3 ? report.counts.status_2 : 0) +
                        report.freedays.length)) *
                      report.member.salary) /
                      30
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}
import { BLink, BOverlay, BFormCheckbox } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BOverlay,
    BFormCheckbox,
  },
  data() {
    return {
      loading: true,
      user: JSON.parse(localStorage.getItem("user")),
      members: [],
      reports: null,
      report: {},
      month:
        new Date().toISOString().split("-")[0] +
        "-" +
        new Date().toISOString().split("-")[1],
    };
  },
  created() {
    var g = this,
      _g = g;
    g.loading = false;
  },
  methods: {
    e(d) {
      return e(d);
    },
    viewReport() {
      var g = this,
        _g = g;
      g.loading = true;
      $.post(api + "/reports/between", {
        jwt: this.user.jwt,
        start: g.month + "-01",
        end:
          g.month +
          "-" +
          getDaysInMonth(g.month.split("-")[0], g.month.split("-")[1]),
        filter: { type: null },
        number: null,
      })
        .then(function (r) {
          r = JSON.parse(r);
          if (r.status == 200) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e(r.response.code),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          } else {
            _g.reports = r.response.arr;
          }
          _g.loading = false;
        })
        .catch((e) => {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: e("error") + e,
              icon: "TimesIcon",
              variant: "danger",
            },
          });
          _g.loading = false;
        });
    },
    printData() {
      var divToPrint = this.$refs.printTable;
      var newWin = window.open("");
      newWin.document.write(`
      <style>
      *{
        direction:rtl
      }
      .noprint{
        display: none;
      }
      table, th, td {
        border: 1px solid;
      }
      td,th{
        padding: 4px
      }
      table{
        width: 100%; border-collapse: collapse;
      }
      </style>
      <h5>تقرير مرتبات شهر ${this.month}</h5>
      `);
      newWin.document.write(divToPrint.outerHTML);
      newWin.print();
      newWin.close();
    },
    count(obj) {
      return Object.keys(obj).length;
    },
  },
};
</script>